<template>
	<div class="ybd-view">
		<designHeader :isSaving="isSaving" :showBtn="showBtn" :hasEdit="hasEdit" :isJz="isJz"></designHeader>
		<div class="container">
			<designLeftNav :fromType='fromType'></designLeftNav>
			<div class="main">
				<div class="template">
					<div class="type">
						<div class="list">
							<div class="item" v-for="item in typeArr" :key="item">
								<span class="label">{{item == 1?'分类':item == 3?'节日':'标签'}}</span>
								<div class="item_list cl" v-if="item == 1">
									<div class="pice" :class="{'active': typeId == 0}" @click="typeId = 0">全部</div>
									<div class="pice" :class="{'active': typeId == o._id}" v-for="o in typeList" :key="o._id" @click="typeId = o._id">{{o.name}}</div>
									<div class="sub_list cl" v-if="typeSubList.length > 0">
										<div class="sub_item" :class="{'active': m._id == typeSubId}" v-for="m in typeSubList" :key="m._id" @click="typeSubId = m._id">{{m.name}}</div>
									</div>
								</div>
								<div class="item_list cl" v-if="item == 3">
									<div class="pice" :class="{'active': festivalId == 0}" @click="festivalId = 0">全部</div>
									<div class="pice" :class="{'active': festivalId == o._id}" v-for="o in festivalList" :key="o._id" @click="festivalId = o._id">{{o.name}}</div>
								</div>
								<div class="item_list cl" v-if="item == 5">
									<div class="pice" :class="{'active': tagId == 0}" @click="tagId = 0">全部</div>
									<div class="pice" :class="{'active': tagId == o._id}" v-for="o in tagList" :key="o._id" @click="tagId = o._id">{{o.name}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tpl">
						<template v-if="list.length > 0">
							<div class="list cl">
								<div class="item" v-for="item in list" :key="item._id">
									<div class="cover">
										<img class="img" v-if="item.modelimg" :src="item.modelimg">
										<img class="no_cover" v-else src="@/assets/images/no-cover.jpg">
									</div>
									<div class="infor">
										<div class="title">{{item.title?item.title:'未命名'}}</div>
										<div class="quote">被引用{{item.quoteNum?item.quoteNum:0}}次</div>
									</div>
									<div class="btns">
										<div class="btn" @click="preview(item)">预览</div>
										<div class="line"></div>
										<div class="btn" @click="edit(item)">选用</div>
									</div>
								</div>
							</div>
							<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
						</template>
						<noData v-else text="暂时还没有表单模板哦！"></noData>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import designHeader from "@/components/designHeader.vue";
import designLeftNav from "@/components/designLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		designHeader,
		designLeftNav,
		noData,
	},
	data(){
		return {
			showBtn:true,
			hasEdit:0,
			isSaving:false,
			isJz: sessionStorage.getItem('isJz')?true:false,
			typeArr: [1,3,5],
			typeList: [],
			typeSubList: [],
			festivalList: [],
			tagList: [],
			typeId: 0,
			typeSubId: 0,
			festivalId: 0,
			tagId: 0,
			list: [],
			listParams:{
				pageNo:1,
				pageSize:20,
			},
			count: 0,
			fromType:'',
		}
	},
	created(){
		this.fromType = this.$route.query.fromType?this.$route.query.fromType:'';
	},
	computed: {
		watchObj(){
			const {typeId,typeSubId,festivalId,tagId} = this;
			return {typeId,typeSubId,festivalId,tagId}
		}
	},
	watch:{
		typeId(){
			this.typeSubId = 0;
			this.getSubTypeList();
		},
		watchObj(){
			if(this.listParams.pageNo == 1){
				this.getTplList();
			}else{
				this.listParams.pageNo = 1;
			}
		},
	},
	mounted(){
		this.getTplList();
	},
	methods: {
		// 获取二级分类列表
		getSubTypeList(){
			const _this = this;
			if(_this.typeId == 0){
				_this.typeSubList = [];
			}else{
				this.getTplList();
			}
		},
		// 获取模板数据列表
		getTplList(){
			const _this = this,
			vocationId = _this.typeId == 0?'':_this.typeId,
			vocationSonId = _this.typeSubId == 0?'':_this.typeSubId,
			festivalId = _this.festivalId == 0?'':_this.festivalId,
			tagId = _this.tagId == 0?'':_this.tagId;

			get('/formadmin/model_list.jhtml',{fromType:_this.fromType,vocationId,vocationSonId,festivalId,tagId,..._this.listParams}).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.date;
					let typeList = res.data.list;
					_this.typeList = typeList.filter(item=>item.type == 1);
					_this.festivalList = typeList.filter(item=>item.type == 3);
					_this.tagList = typeList.filter(item=>item.type == 5);
					if(res.data.vocationList){
						_this.typeSubList = res.data.vocationList
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getTplList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getTplList();
		},
		// 收藏表单
		collect(id){
			const _this = this;
			post('/formadmin/form_collect/collect.jhtml',{formId:id}).then(res => {
				if(res.code == 200){
					_this.getTplList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 预览
		preview(item){
			const _this = this;
			let url = '';
			if(item.fromType == 'form'){
				url = '/preview';
			}else if(item.fromType == 'exam'){
				url = '/exam_preview';
			}else{
				url = '/ques_preview';
			}
			_this.$router.push({
				path:url,
				query:{
					formId:item._id,
					userId:item.userId
				}
			})
		},
		// 选用
		edit(item){
			const _this = this;
			let url = '';
			if(item.fromType == 'form'){
				url = '/questionTypes';
			}else if(item.fromType == 'exam'){
				url = '/exam_questionTypes';
			}else{
				url = '/ques_questionTypes';
			}
			_this.$router.push({
				path:url,
				query:{
					formId:item._id,
					userId:item.userId
				}
			})
		}
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.template {
		flex: 1;
		display: flex;
		flex-direction: column;
		.type {
			padding: 10px 30px;
			background-color: #fff;
			.list{
				.item{
					display: flex;
					padding: 5px 0;
					.label{
						line-height: 50px;
						margin: 0 20px 0 0;
					}
					.item_list{
						flex: 1;
						padding: 10px 0;
						border-bottom: 1px solid #e5e5e5;
						.pice{
							float: left;
							line-height: 30px;
							padding: 0 10px;
							margin: 0 10px 0 0;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
						.active{
							color: var(--theme-color);
						}
						.sub_list{
							float: left;
							width: 100%;
							line-height: 24px;
							padding: 10px 0;
							background-color: #f5f5f5;
							.sub_item{
								float: left;
								padding: 0 10px;
								margin: 0 10px 0 0;
								cursor: pointer;
								&:hover{
									color: var(--theme-color);
								}
							}
						}
					}
					&:last-child{
						.item_list{
							border: none;
						}
					}
				}
			}
		}
		.tpl {
			flex: 1;
			margin: 16px 0 0;
			background-color: #fff;
			padding:20px 20px;
			.list{
				.item{
					float: left;
					width: 240px;
					margin: 10px;
					border: 1px solid #e5e5e5;
					border-radius: 4px;
					background-color: #fff;
					overflow: hidden;
					.cover{
						height: 120px;
						overflow: hidden;
						position: relative;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: all .3s;
						}
						.no_cover{
							border-bottom: 1px solid #e5e5e5;
						}
						
					}
					.infor{
						padding: 14px;
						line-height: 24px;
						.title{
							color: #666;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.quote{
							font-size: 12px;
							color: #999;
						}
					}
					.btns{
						display: flex;
						padding: 10px;
						border-top: 1px solid #e5e5e5;
						.btn{
							flex: 1;
							text-align: center;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
						.line{
							width: 1px;
							height: 20px;
							background-color: #ddd;
						}
					}
					&:hover{
						box-shadow: 0 0 10px rgba(0, 0, 0, .2);
						.cover{
							.img{
								transition: all .3s;
								transform: scale(1.1);
							}
						}
					}
				}
			}
			::v-deep .el-pagination{
				padding: 20px 10px 10px;
				font-weight: normal;
				text-align: right;
				.el-pagination__total,
				.el-pagination__sizes{
					float: left;
					.el-input{
						.el-input__inner{
							&:hover{
								border-color: var(--theme-color);
							}
						}
					}
				}
				.el-pager{
					li:not(.disabled){
						&:hover{
							color: var(--theme-color);
						}
					}
					li:not(.disabled).active{
						background-color: var(--theme-color);
						&:hover{
							color: #fff;
						}
					}
				}
				.btn-prev,
				.btn-next{
					font-size: 12px;
					padding: 0 5px;
				}
			}
		}
	}

}
</style>